@import '~antd/dist/antd.css';
* {
  outline: none;
}

.ant-select-lg .ant-select-selection--single {
  height: 49px;
  width: 200px;
  font-size: xx-large;
}

.ant-select-lg .ant-select-selection__rendered {
  line-height: 49px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 10px;
  white-space: nowrap;
  zoom: 1.4;
}

.ant-layout-content {
  min-height: 300px;
  padding: 20px;
}
.ant-layout-header,
.ant-layout-footer {
  background: #fefefe;
  text-align: center;
}

.ant-layout-footer {
  margin-top: 30px;
}

.passo1 {
  text-align: center;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

input {
  width: 100%;
  padding: 0 10px;
}

.ant-form-item-control span {
  color: #f5222d;
}
.has-error.ant-form-item-control {
  background-color: #fff;
  border-color: #f5222d;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 40px;
  padding-bottom: 40px;
}

body {
  background: #f0f2f5;
}

.steps-action {
  margin-top: 24px;
}

.calculo {
  font-size: 40px;
  color: #525252 !important;
  width: 100%;
  padding: 0 10px;
  box-shadow: none;
  border: solid 1px #e3e3e3;
  text-align: right;
}

.ant-message {
  z-index: 10010;
}

@media (max-width: 600px) {
  .ant-steps-item-active {
    display: inline-block;
  }
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    display: none !important;
  }
  .ant-steps-item-title {
    font-size: 8vw;
  }
}
